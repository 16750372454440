<template>
  <b-form @submit="changePassword">
    <b-card v-if="errors" bg-variant="danger">
      <div v-for="(v, k) in errors" :key="k">
        <p v-for="error in v" :key="error" class="text-sm text-light">
          {{ error }}
        </p>
      </div>
    </b-card>
    <b-form-group id="input-group-2" label="Aktualne hasło:" label-for="current-pass"
                  description="Wprowadź obecnie używane hasło. Domyślne hasło to: defpass">
      <b-form-input
          id="current-pass"
          v-model="form.current_password"
          type="password"
          placeholder="Obecne hasło"
          required
      ></b-form-input>
    </b-form-group>
    <b-form-group id="input-group-2" label="Nowe hasło:" label-for="current-pass"
                  description="Wprowadź nowe hasło. Mimalna ilość znaków 6">
      <b-form-input
          id="current-pass"
          v-model="form.new_password"
          placeholder="Nowe hasło"
          type="password"
          required
      ></b-form-input>
    </b-form-group>
    <b-form-group id="input-group-2" label="Powtórz hasło:" label-for="current-pass"
                  description="Wprowadź jeszcze raz nowe hasło.">
      <b-form-input
          id="current-pass"
          v-model="form.new_password_confirm"
          placeholder="Nowe hasło"
          type="password"
          required
      ></b-form-input>
    </b-form-group>
    <b-form-group id="input-group-2" label="Adres e-mail:" label-for="current-pass"
                  description="Podaj adres e-mail. Jest on niezbędny w celu przywrócenia hasła, gdy go zapomnisz.">
      <b-form-input
          id="current-pass"
          v-model="form.email"
          placeholder="e-mail"
          type="email"
          required
      ></b-form-input>
    </b-form-group>
    <b-button type="submit" variant="primary" class="mt-5" :disabled="loading">
      <b-spinner small type="grow" v-if="loading"></b-spinner>
      Ustaw nowe hasło
    </b-button>
  </b-form>
</template>


<script>

export default {
  name: 'ChangePasswordForm',
  data() {
    return {
      loading: false,
      errors: null,
      form: {
        current_password: '',
        new_password: '',
        new_password_confirm: '',
        email: '',
      },
    }
  },
  methods: {
    async changePassword(event) {
      event.preventDefault();
      //   this.loading = true;
      this.errors = null;
      await this.$http.post(this.$api.CHANGE_PASSWORD, this.form).then(() => {
        this.$notify({
          group: 'default',
          title: 'Hasło zostało zmienione.',
          type: 'success',
          duration: 10000,
          text: ''
        });
      }).catch((error) => {
        this.errors = error.response.data.errors;
        this.$notify({
          group: 'default',
          title: 'Błąd w formularzu.',
          type: 'error',
          duration: 10000,
          text: 'Błąd w formualarzu. Sprawdź błędy'
        });
      });
      this.loading = false;
    },
  }
}
</script>

