<template>
  <div>
    <div class="jumbotron">
      <h1 class="display-4">Panel sterowania</h1>
      <b-alert style="max-width: 600px;margin: auto auto;" class="mb-2" show dismissible
               v-if="!form.password_changed" variant="warning">
        <small>
          <b>&rArr;</b> Wciąż masz domyślne hasło na urządzeniu! Ustaw bezpieczniejsze hasło. <b>&lAarr;</b></small>
      </b-alert>
      <b-card no-body style="max-width: 600px;margin: 0 auto;">
        <b-tabs card>
          <b-tab title="Ustawienia licznika" active>
            <b-form @submit="onSubmit">
              <b-form-group label="Platforma" label-for="platform-id"
                            description="Wybierz platformę, którą chcesz monitorować.">
                <b-form-select
                    id="platform-id"
                    text-field="label"
                    value-field="value"
                    v-model="form.platform_id"
                    :options="platforms"
                    required
                ></b-form-select>
              </b-form-group>
              <template v-if="form.platform_id === 1">
                <b-form-group id="input-group-2" label="Nick z TikToka:" label-for="ttlogin-2"
                              description="Podaj swój login z tiktoka. np. tiktok.com/@mikinik23, mikinik23 będzie nickiem.">
                  <b-form-input
                      id="ttlogin-2"
                      v-model="form.settings.url.id"
                      placeholder="Wprowadź swój nick"
                      required
                  ></b-form-input>
                </b-form-group>
              </template>
              <template v-else-if="form.platform_id === 2">
                <b-form-group id="input-group-2" label="Nick z Instagrama:" label-for="instalogin-2"
                              description="Podaj swój login z instagrama. np. instagram.com/@miki_nik23, miki_nik23 będzie nickiem.">
                  <b-form-input
                      id="instalogin-2"
                      v-model="form.settings.url.id"
                      placeholder="Wprowadź swój nick"
                      required
                  ></b-form-input>
                </b-form-group>
              </template>
              <template v-else>
                <div>
                  Wybierz platformę, aby zobaczyć dodatkowe ustawienia.
                </div>
              </template>
              <b-button type="submit" variant="primary" class="mt-5" :disabled="loading">
                <b-spinner small type="grow" v-if="loading"></b-spinner>
                Zapisz zmiany
              </b-button>
            </b-form>
          </b-tab>
          <b-tab title="Zmiana hasła">
            <change-password-form></change-password-form>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>

import ChangePasswordForm from "@/components/ChangePasswordForm";

export default {
  name: 'Settings',
  components: {ChangePasswordForm},
  data() {
    return {
      loading: false,
      form: {
        platform_id: 1,
        password_changed: 1,
        settings: {
          url: {},
        },
      },
      platforms: [],
    }
  },
  async mounted() {
    this.loading = true;
    await this.getPlatforms();
    await this.getDevice();
    this.loading = false;
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.loading = true;
      await this.$http.patch(this.$api.DEVICE, this.form).then(() => {
        this.$notify({
          group: 'default',
          title: 'Dane licznika zostały zmienione',
          type: 'success',
          duration: 10000,
          text: 'Zresetuj licznik aby zastosować zmiany.'
        });
      });
      this.loading = false;
    },
    changePassword() {

    },
    getPlatforms() {
      this.$http.get(this.$api.PLATFORMS).then((response) => {
        this.platforms = response.data.data;
      });
    },
    getDevice() {
      this.$http.get(this.$api.DEVICE).then((response) => {
        Object.keys(response.data).forEach((key) => {
          let value = response.data[key];
          console.log(key);
          console.log(value);
          if (value != null) {
            this.$set(this.form, key, value);
          }
        });
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.jumbotron {
  margin-bottom: 0;
}
</style>
